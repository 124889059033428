import {http} from './config';

export default {
    createAllotmentReadjustment(allotmentId, data) {
        return http.post(`api/v1/reajustes/loteamento/${allotmentId}`, data);
    },
    createBlockReadjustment(allotmentId, body) {
        return http.post('api/v1/reajustes/quadras', body,{headers: {'allotment-id': allotmentId}});
    },
    createLotReadjustment(allotmentId, body) {
        return http.post('api/v1/reajustes/propriedades', body,{headers: {'allotment-id': allotmentId}});
    },
    createLotTypeReadjustment(allotmentId, body) {
        return http.post('api/v1/reajustes/tipo-propriedades', body,{headers: {'allotment-id': allotmentId}});
    },
    index(allotment_id, filter, page = 1, perpage = null) {
        if (perpage === 'selecione' || !perpage) perpage = null;
        return http.get('api/v1/gruposReajustes?page=' + page, {headers:{'allotment-id': allotment_id},
            params: {...filter, perpage}});
    },
    show(id) {
        return http.get(`api/v1/gruposReajustes/${id}`);
    }
}