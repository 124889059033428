import {http} from './config';

export default {
    create:(type) => {
        return http.post('api/v1/tipoPropriedades', type);
    },
    index:(page=1) => {
        return http.get('api/v1/tipoPropriedades?page=' + page);
    },
    update:(type) => {
        return http.put('api/v1/tipoPropriedades/' + type.id, type);
    },
    delete: (id) => {
        return http.delete('api/v1/tipoPropriedades/' + id);
    },
    list: () => {
        return http.get('api/v1/tipoPropriedades/listar');
    },
    listPublic: (companyId) => {
        return http.get('api/v1/publica/tipoPropriedades/listar',{headers: {'company-id': companyId}});
    }
}