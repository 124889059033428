import {http} from './config';

export default {
    
    indexSituation:() => {
        return http.get('api/v1/propriedades/situacao');
    },
    listStatusComplet:() => {
        return http.get('api/v1/propriedades/situacao/listar',{params:{complet:true}});
    },
    alterStatusByAllotment:(data) => {
        return http.post('api/v1/alterarStatusEmMassa/loteamento', data);
    },
    alterStatusByBlock:(data) => {
        return http.post('api/v1/alterarStatusEmMassa/quadras', data);
    },
    alterStatusByProperty:(data) => {
        return http.post('api/v1/alterarStatusEmMassa/propriedades', data);
    },
    listStatusCompletPublic:(companyId) => {
        return http.get('api/v1/publica/propriedades/situacao/listar',{headers: {'company-id': companyId},params:{complet:true}});
    },
    listSituation:() => {
        return http.get('api/v1/propriedades/situacao/selecionar');
    },
    getById:(allotment_id, block) => {
        return http.get('api/v1/propriedades/'+ allotment_id, + block);
    },
    updateSituation:(situation) => {
        return http.put('api/v1/propriedades/situacao/' + situation.id, situation)
    },
}