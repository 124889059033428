<template>
    <div class="modal fade" id="modalShow" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true"
         style="position: fixed;" >
        <button type="button" class="closeX" id="closeModal" data-dismiss="modal" aria-label="Close" hidden></button>
        <div class="modal-dialog modal-sm" role="document">
            <div class="modal-content" >

                <div class="modal-body text-center" v-if="lotsIndex.status">
                    <h4>{{ t(modalTitle) }}</h4>

                    <div class="info-stats4 w-100 full-border">
                        <div class="row gutters">

                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3"></div>
                            <div class="name col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6"
                                 :style="{'background-color': status.backgroud_collor, color: status.text_collor}">
                                <h3>{{ lots.name }}</h3>
                                <h5>{{ status.name }}</h5>
                            </div>

                            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1"></div>
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                <a id="task-actions" aria-haspopup="true" data-toggle="dropdown" class="pointer"
                                   v-if="hasUpdatePermission || hasDeletePermission || hasShowPermission">
                                    <i class="icon-dehaze btn"/>
                                </a>
                                <div aria-labelledby="task-actions" class="dropdown-menu dropdown-menu-right custom"
                                     id="dropdownMenu">
                                    <p v-if="hasShowPermission" type="button" data-toggle="modal"
                                       @click="$emit('view', lots?.lot?.id)"
                                       data-target="#customModalTwo" class="pointer">
                                        <i class="icon-eye dropdown-iten-padding"></i> {{ t('GENERAL.MORE_INFO') }}
                                    </p>
                                    <p v-if="hasUpdatePermission" data-toggle="modal"
                                       @click="$emit('update', lots?.lot?.id)"
                                       data-target="#customModalTwo" class="pointer">
                                        <i class="icon-mode_edit dropdown-iten-padding"></i> {{ t('ACTIONS.EDIT') }}
                                    </p>
                                    <!--                           <p v-if="hasDeletePermission" type="button" @click="$emit('delete', lots)" class="pointer">-->
                                    <!--                              <i class="icon-close dropdown-iten-padding"></i> {{ t('ACTIONS.DELETE') }}-->
                                    <!--                           </p>-->
                                </div>
                            </div>
                            <h4 class="font-weight-bold mt-1" style="width: 100%">{{ toCurrency(lots.price) }}</h4>
                            <div class="sale-num col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1"></div>
                            <div class="sale-num col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5"><p class="secondary">Quadra
                                {{ lots.block }}</p></div>
                            <div class="sale-num col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5"><p class="secondary">
                                {{ lots.total_area }}m²</p></div>
                            <div class="sale-num col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1"></div>
                        </div>
                    </div>

                    <div class="space-between" :style="{'justify-content': formartButtons()}">
                        <button data-cy="lot_show_reserve" v-if="hasReservePermission && status.type === 0"
                                type="button"
                                data-toggle="modal"
                                class="btn btn-primary mr-1"
                                @click="$emit('reserve', lots)" data-target="#customModalTwo">
                            {{ t('LOTS.RESERVE') }}
                        </button>
                        <button v-if="canProposeSimulate && (lots.price && lots.price != 0) && status.type === 1"
                                type="button" data-toggle="modal"
                                class="btn btn-primary mr-1"
                                @click="$emit('simulate', lots, 'propose')" data-target="#customModalTwo">
                            {{ t('LOTS.CREATE_PROPOSE') }}
                        </button>
                        <button data-cy="lot_show_simulate" type="button" data-toggle="modal"
                                class="btn btn-primary mr-1"
                                data-target="#customModalTwo"
                                v-if="(status.type === 0 && !canProposeSimulate) || (canProposeSimulate && status.type === 1 && lots.price)"
                                @click="$emit('simulate', lots, 'simulate')">
                            {{ t('GENERAL.SIMULATOR') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {useI18n} from 'vue-i18n';
import Permission from '@/components/permission.vue';

export default {
    name: 'showLot',

    mixins: [Permission],

    emits: ['simulate', 'reserve'],

    props: {
        lotsIndex: {
            type: Object,
            required: true,
        },
        modalTitle: {
            type: String,
            required: true,
        },
    },

    setup() {
        const {t} = useI18n();
        return {t};
    },

    data() {
        return {
            lots: {},
            status: {},
            hasShowPermission: false,
            canProposeSimulate: false,
            hasDeletePermission: false,
            hasUpdatePermission: false,
            hasReservePermission: null,
        };
    },

    mounted() {
        this.lots = this.lotsIndex;
        if (this.lots.status){
            this.status = this.lotsIndex.status;
            this.permissonByStatus(this.status);
            this.checkIfCanSimulatePropose();
            this.hasReservePermission = this.lotsIndex.status.type == 0;
            this.hasShowPermission = this.validatePermissions('Lot:show');
        }
    },

    watch: {
        lotsIndex: function () {
            this.updateModalInfs();
        },
    },

    methods: {
        updateModalInfs() {
            this.lots = this.lotsIndex;
            this.status = this.lotsIndex.status;
            this.checkIfCanSimulatePropose();
            this.permissonByStatus(this.status);
            this.hasReservePermission = this.lotsIndex.status.type == 0;
            this.hasShowPermission = this.validatePermissions('Lot:show');
        },


        permissonByStatus(status) {
            this.hasDeletePermission = (status.type == 0 || status.type == 4) ? this.validatePermissions('Lot:delete') : false;
            this.hasUpdatePermission = (status.type == 0 || status.type == 4 || status.type == 3 || ((status.type == 2) && this.lots?.allotments?.id_configuracao_bd < 0)) ? this.validatePermissions('Lot:update') : false;
        },

        toCurrency(value) {
            if (value === null) return 'Indisponível';
            return Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(value);
        },

        formartButtons() {
            return !this.hasReservePermission ? 'end !important' : 'space-between !important';
        },

        checkIfCanSimulatePropose() {
            this.canProposeSimulate = this.lots.status.name === 'Reservado';
        },
    },
};
</script>

<style>
.space-between {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px);
}

.name {
    margin: auto !important;
    border-radius: 7px;
    padding-bottom: 1px;
    padding-top: 6px;
}

.secondary {
    border-radius: 3px;
    color: #ffffff !important;
    background-color: #383737;
}

.full-border {
    border-radius: 2px !important;
    border-bottom: 1px solid #dbd4d4 !important;
    border-top: 1px solid #dbd4d4 !important;
    border-left: 1px solid #dbd4d4 !important;
    border-right: 1px solid #dbd4d4 !important;
}

.custom {
    color: white;
    border-radius: 4px;
    top: 5px !important;
    left: 3px !important;
    background: #262b31;
}

.custom:before {
    border-bottom: 10px solid #262b31 !important;
}

.pointer {
    cursor: pointer;
}

.dropdown-iten-padding {
    padding-left: 0.8rem;
    padding-right: 0.2rem;
}
</style>
