<template>
  <div v-if="uploadFile" :style="absolutePosition ? { position: 'absolute', 'z-index': 9 } : {}" >
    <div class="modal-mask" @click="close($event)">
      <div class="modal-wrapper" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content file-modal">
            <div class="modal-body modal-open">
              <div class="drop-zone" id="uploder" @dragover.prevent @drop.prevent="dropFile">
                <div class="row gutters">
                  <div class="col-xl-12 col-lglg-12 col-md-12 col-sm-12 col-12 mb-3" v-if="!file">
                    <i class="icon-upload-to-cloud file-icon"/> 
                  </div>
                  <div class="col-xl-12 col-lglg-12 col-md-12 col-sm-12 col-12" v-if="!file">
                      <p style="color: black">{{t('GENERAL.MSG.DRAG_FILE_HERE')}}<br/>{{t('GENERAL.MSG.OR_CLICK_BELLOW')}}</p>
                  </div>
                  <div class="col-xl-12 col-lglg-12 col-md-12 col-sm-12 col-12" v-if="file">
                    <div style="align-items: center;">
                    <a style="color: black">{{file.name}}</a>
                      <button type="button" class="close" aria-label="Close" @click.stop.prevent="deleteFile">
                        <span style="color: #0e5e4e" >&times;</span>
                      </button>
<!--                      icon-x ml-2 red pointer-->
                    </div>
                  </div>
                  <div class="col-xl-12 col-lglg-12 col-md-12 col-sm-12 col-12 mt-5 text-center" v-if="!file">
                    <label class="btn btn-primary" for="import">{{t('GENERAL.SELECT_FILE')}}</label>
                    <input data-cy="lot_index_input_import" class="drop-zone__input" id="import" type="file" ref="file" @change="addFile" 
                      accept=".png, .jpg, .jpeg, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf,"/>
                  </div>
                  <div class="col-xl-12 col-lglg-12 col-md-12 col-sm-12 col-12 mt-5 text-center" v-if="file">
                    <label class="btn btn-primary" @click="importFile()">{{t('GENERAL.SEND_FILE')}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {useI18n} from "vue-i18n";

export default {
  name: 'uploadModal',
  props: ['uploadFile', 'absolutePosition'],
  setup() {
    const {t} = useI18n();
    return {t}
  },

  data() {
    return {
      file: null,
    }
  },

  methods: {
    close(event) {
      let target = event.target;
      do {
        if (target == document.getElementById('uploder')) {
          this.$emit('upload', true);
          return;
        }
        target = target.parentNode;
      } while (target);
      this.$emit('upload', false);
    },

    addFile(event) {
      this.file = event.target.files[0];
    },

    dropFile(event) {
      this.file = event.dataTransfer.files[0];
    },

    deleteFile() {
      this.file = null;
    },

    importFile() {
      this.$emit('import', this.file);
      this.deleteFile();
    }
  }
};
</script>

<style scoped>
.file-modal {
  opacity : 0.9; 
  background-color: rgb(171 171 171);
}
.modal-body{ 
  padding: 0.7rem;
}
.file-icon {
  color: #0e5e4e;
  font-size: 180px;
}

.drop-zone {
  max-width: 100%;
  height: 400px;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: #cccccc;
  border: 4px dashed #009578;
  border-radius: 10px;
}
.drop-zone--over {
  border-style: solid;
}
.drop-zone__input {
  display: none;
}
.drop-zone__thumb {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: #cccccc;
  background-size: cover;
  position: relative;
}
.drop-zone__thumb::after {
  content: attr(data-label);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px 0;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.75);
  font-size: 14px;
  text-align: center;
}
</style>